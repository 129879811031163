import { Fragment } from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Logo from '../../UI/Logo/Logo';
import UserMenu from '../../UserMenu/UserMenu';

const Header = () => {
  const userIsLoggedIn = useSelector((state) => state.person.isLoggedIn);
  const { t } = useTranslation(['translation']);

  return (
    <header>
      <Navbar expand="sm" className="navbar-bg col-12 py-0">
        <Container>
          <div className="d-flex align-items-center">
            <Navbar.Brand href="/" className="p-0">
              <Logo />
            </Navbar.Brand>
            <h3 className="d-none d-lg-inline-block text-center ml-3 mb-0">
              {t('translation:business-name')}
            </h3>
          </div>
          {
            userIsLoggedIn ?
              <Fragment>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                  <UserMenu />
                </Navbar.Collapse>
              </Fragment>
              : null
          }
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
