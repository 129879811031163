import React, { useRef } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';

import HelpCenterMessage from '../../HelpCenterMessage/HelpCenterMessage';

const MainContent = ({ children }) => {
  const location = useLocation();
  const nodeRef = useRef(null);

  return (
    <Container as="main" role="main" className="content px-1">
      <Container as="section" className="pt-3">
        <TransitionGroup className="row">
          <CSSTransition
            key={location.pathname}
            classNames="fade"
            nodeRef={nodeRef}
            unmountOnExit
            timeout={{ enter: 500, exit: 200 }}>
            <Col xs="12" className="routeFadeIn">
              <Col xs="12" md="8" lg="7" className="m-auto">
                <Row>
                  <Col xs="12">
                    {children}
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <HelpCenterMessage className="mt-4" />
                  </Col>
                </Row>
              </Col>
            </Col>
          </CSSTransition>
        </TransitionGroup>
      </Container>
    </Container>
  );
};

export default MainContent;
