import { configureStore, combineReducers } from '@reduxjs/toolkit';

import paymentIntentReducer, { defaultPaymentIntentStatus } from './reducers/payment-intent';
import personReducer, { defaultPersonState } from './reducers/person';
import receivableReducer, { defaultReceivableState } from './reducers/receivable';
import { AppAction } from '../constants/AppAction';

const combinedReducer = combineReducers({
  payment: paymentIntentReducer,
  person: personReducer,
  receivable: receivableReducer,
});

const rootReducer = (state, action) => {
  if (action.type === AppAction.LogOut) {
    state = {
      person: defaultPersonState,
      receivable: defaultReceivableState,
      payment: defaultPaymentIntentStatus
    };
    sessionStorage.clear();
  }

  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer
});

export default store;
