import { Redirect, Route } from 'react-router';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children, ...rest }) => {
  const userIsLoggedIn = useSelector((state) => state.person.isLoggedIn);

  return (
    <Route {...rest} render={({ location }) =>
    userIsLoggedIn ? (children) : (<Redirect to={{ pathname: '/', state: { from: location } }} />)
    }
    />
  )
};

export default PrivateRoute;
