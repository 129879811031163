import fetchIntercept from 'fetch-intercept';

export const unregister = fetchIntercept.register({
  request: function (url, config) {
    url = `${process.env.REACT_APP_API_URL}/api${url}`;

    return [url, config];
  },

  requestError: function (error) {
    return Promise.reject(error);
  },

  response: function (response) {
    return response;
  },

  responseError: function (error) {
    return Promise.reject(error);
  }
});
