import { useTranslation } from 'react-i18next';

import * as classes from './Logo.module.scss';

const Logo = ({ height, width }) => {
  const { t } = useTranslation(['translation']);

  return (
    <div
      style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/logo/logo.png)`, width, height }}
      className={classes.Logo}
      title={t('translation:business-name')}></div>
  );
};

export default Logo;
