import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';

import { AppUrl } from '../../constants/AppUrl';
import PrivateRoute from './PrivateRoute/PrivateRoute';

const EnterCode = React.lazy(() => import('./../../pages/EnterCode/EnterCode'));
const PendingAccounts = React.lazy(() => import('./../../pages/PendingAccounts/PendingAccounts'));
const PaymentSummary = React.lazy(() => import('./../../pages/PaymentSummary/PaymentSummary'));
const PaymentStatus = React.lazy(() => import('./../../pages/PaymentStatus/PaymentStatus'));

const Routes = () => {
  const location = useLocation();

  return (
    <Switch location={location}>
      <Redirect from={AppUrl.Root} to={AppUrl.EnterCode} exact />

      <Route path={AppUrl.EnterCode} component={EnterCode} exact />

      <PrivateRoute path={AppUrl.PendingAccounts} exact>
        <PendingAccounts />
      </PrivateRoute>

      <PrivateRoute path={AppUrl.PaymentSummary} exact>
        <PaymentSummary />
      </PrivateRoute>

      <PrivateRoute path={AppUrl.PaymentStatus} exact>
        <PaymentStatus />
      </PrivateRoute>

      <Route path='*' component={EnterCode} />
    </Switch>
  );
};

export default Routes;
