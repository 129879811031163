import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AppAction } from '../../constants/AppAction';

import * as classes from './UserMenu.module.scss';

const UserMenu = () => {
  const dispatch = useDispatch();
  const fullName = useSelector((state) => state.person.fullName);
  const { t } = useTranslation(['translation']);

  const logoutHandler = () => {
    dispatch({ type: AppAction.LogOut });
  };

  return (
    <ul className="list-inline text-right mb-0">
      <li className="list-inline-item" title={fullName}>
        <span className={[classes.FullName, 'd-inline-block text-truncate align-middle'].join(' ')} >
          {fullName}
        </span>
      </li>
      <li className="list-inline-item mr-1">
        <span className="align-middle">|</span>
      </li>
      <li className="list-inline-item">
        <a href="/" onClick={logoutHandler} className="align-middle">
          <i className="fas fa-fw fa-sign-out-alt"></i>
          {t('translation:safe-exit')}
        </a>
      </li>
    </ul>
  )
};

export default UserMenu;
