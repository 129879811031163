import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const HelpCenterMessage = ({ className }) => {
  const { t } = useTranslation(['help-center']);

  return (
    <Alert variant="warning" className={[className, 'col-xs-12'].join(' ')}>
      <div className="alert-message text-center">
        <h4>{t('help-center:title')}</h4>
        <h5>
          <a href={`tel:${t('help-center:phone')}`}>
            {t('help-center:full-phone')}
            <i className="ml-2 fas fa-fw fa-phone"></i>
          </a>
        </h5>
        <h5 className="text-break">
          <a href={`mailto:${t('help-center:email')}`}>
            {t('help-center:email')}
          </a>
        </h5>
      </div>
    </Alert>
  );
};

export default HelpCenterMessage;
