import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation(['translation']);

  return (
    <Col xs="12" as="footer" className="footer">
      <Container>
        <Row>
          <Col xs="12" className="d-flex justify-content-between align-items-center">
            <img src={`${process.env.PUBLIC_URL}/assets/img/logo/logo-drino.png`} alt={t('translation:drino-copy')} width="30" />
            <span className="text-muted">
              {t('translation:drino-copy')}
            </span>
          </Col>
        </Row>
      </Container>
    </Col>
  );
};

export default Footer;
