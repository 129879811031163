import React, { Suspense } from 'react';

import Layout from './components/Navigation/Layout/Layout';
import Loader from './components/UI/Loader/Loader';
import Routes from './components/Routes/Routes';

function App() {
  return (
    <Suspense fallback={<Loader />} >
      <Layout>
        <Routes />
      </Layout>
    </Suspense >
  );
}

export default App;
