import { Fragment } from 'react';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import MainContent from '../MainContent/MainContent';

const Layout = ({ children }) => {
  return (
    <Fragment>
      <Header></Header>
      <MainContent>
        {children}
      </MainContent>
      <Footer></Footer>
    </Fragment>
  );
};

export default Layout;
