import { createSlice } from '@reduxjs/toolkit';

export const defaultPaymentIntentStatus = {
  indentId: null
};

const initialState = () => {
  return {
    intentId: sessionStorage.getItem('intentId') || null
  };
};

const paymentIntentSlice = createSlice({
  name: 'payment',
  initialState: initialState(),
  reducers: {
    setIntentId: (state, action) => {
      state.intentId = action.payload;
      sessionStorage.setItem('intentId', action.payload);
    }
  },
});

export const paymentIntentActions = paymentIntentSlice.actions;

export default paymentIntentSlice.reducer;
