import { createSlice } from '@reduxjs/toolkit';

export const defaultPersonState = {
  docNumber: null,
  isLoggedIn: false,
  fullName: null
};

const initialState = () => {
  let user = null;

  try {
    user = JSON.parse(sessionStorage.getItem('person')) || {};
  } catch {
    user = {};
  };

  return {
    docNumber: user.docNumber || null,
    isLoggedIn: !!user.docNumber,
    fullName: user.fullName || ''
  }
};

const personSlice = createSlice({
  name: 'person',
  initialState: initialState(),
  reducers: {
    setPersonData: (state, action) => {
      const { docNumber, fullName } = action.payload;

      state.docNumber = docNumber;
      state.fullName = fullName;
      state.isLoggedIn = true;

      sessionStorage.setItem('person', JSON.stringify({ docNumber, fullName }));
    }
  },
});

export const personActions = personSlice.actions;

export default personSlice.reducer;
