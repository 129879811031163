
import { createSlice } from '@reduxjs/toolkit';

export const defaultReceivableState = {
  pending: [],
  selected: [],
  totalAmount: 0
};

const initialState = () => {
  let receivables = null;
  let selected = null;

  try {
    receivables = JSON.parse(sessionStorage.getItem('receivables')) || [];
    selected = JSON.parse(sessionStorage.getItem('selected')) || [];
  } catch {
    receivables = [];
    selected = [];
  };

  const totalAmount = (selected.length > 0) ? selected.reduce((a, b) => a + (b.amount || 0), 0) : 0;

  return {
    pending: receivables,
    selected: selected,
    totalAmount: totalAmount
  }
};

const receivableSlice = createSlice({
  name: 'receivable',
  initialState: initialState(),
  reducers: {
    clear: (state) => {
      state = initialState();
    },
    setReceivable: (state, action) => {
      state.pending = action.payload;
      sessionStorage.setItem('receivables', JSON.stringify(action.payload));
    },
    removeReceivable: (state, action) => {
      state.selected = state.selected.filter((selected) => selected.id !== action.payload.id);
      state.totalAmount -= action.payload.amount;
      sessionStorage.setItem('selected', JSON.stringify(state.selected));
    },
    selectReceivable: (state, action) => {
      state.selected.push(action.payload);
      state.totalAmount += action.payload.amount;
      sessionStorage.setItem('selected', JSON.stringify(state.selected));
    },
    removeSelected: (state) => {
      state.selected = [];
      sessionStorage.removeItem('selected');
    },
    removeReceivables: (state) => {
      state.pending = [];
      sessionStorage.removeItem('receivables');
    }
  },
});


export const receivableActions = receivableSlice.actions;

export default receivableSlice.reducer;
