import { Spinner } from 'react-bootstrap';

const Loader = () => {
  return (
    <div style={{ position: 'absolute', left: '50%', top: '50%' }}>
      <Spinner animation="border" role="status">
        <span className="sr-only">Cargando...</span>
      </Spinner>
    </div>
  );
};

export default Loader;
